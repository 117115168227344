import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="magic-overview"></a><h2>Magic Overview</h2>
    <p>A spell is a one-time magical effect. Spells come in two
types: arcane (cast by bards, sorcerers, and wizards) and divine (cast
by clerics, druids, and experienced paladins and rangers). Some
spellcasters select their spells from a limited list of spells known,
while others have access to a wide variety of options. Most
spellcasters prepare their spells in advance&#8212;whether from a spellbook
or through devout prayers and meditation&#8212; while some cast spells
spontaneously without preparation. Despite these different ways that
characters use to learn or prepare their spells, when it comes to
casting them, the spells are very much alike.</p>
    <p>Cutting across the categories of arcane and divine spells are
the eight schools of magic. These schools represent the different ways
that spells take effect.</p>
    <a id="casting-spells"></a><h3>CASTING SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Whether a spell is arcane or divine, and
whether a character prepares spells in advance or chooses them on the
spot, casting a spell works the same way.</p>
    <a id="choosing-a-spell"></a><h5>CHOOSING A SPELL</h5>
    <p className="initial">First you must choose which spell to cast. If
you&#8217;re a cleric, druid, experienced paladin, experienced ranger, or
wizard, you select from among spells prepared earlier in the day and
not yet cast (see Preparing Wizard Spells and Preparing Divine Spells).
If you&#8217;re a bard or sorcerer, you can select any spell you know,
provided you are capable of casting spells of that level or higher.</p>
    <p>To cast a spell, you must be able to speak (if the spell has a
verbal component), gesture (if it has a somatic component), and
manipulate the material components or focus (if any). Additionally, you
must concentrate to cast a spell.</p>
    <p>If a spell has multiple versions, you choose which version to
use when you cast it. You don&#8217;t have to prepare (or learn, in the case
of a bard or sorcerer) a specific version of the spell.</p>
    <p>Once you&#8217;ve cast a prepared spell, you can&#8217;t cast it again
until you prepare it again. (If you&#8217;ve prepared multiple copies of a
single spell, you can cast each copy once.) If you&#8217;re a bard or
sorcerer, casting a spell counts against your daily limit for spells of
that spell level, but you can cast the same spell again if you haven&#8217;t
reached your limit.</p>
    <a id="concentration"></a><h5>CONCENTRATION</h5>
    <p className="initial">To cast a spell, you must concentrate. If
something interrupts your concentration while you&#8217;re casting, you must
make a <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check or lose the
spell. The more distracting the
interruption and the higher the level of the spell you are trying to
cast, the higher the DC is. If you fail the check, you lose the spell
just as if you had cast it to no effect.</p>
    <a id="injury"></a><p><b>Injury</b>: If while trying to cast a spell you take
damage, you must make a Concentration check (DC 10 + points of damage
taken + the level of the spell you&#8217;re casting). If you fail the check,
you lose the spell without effect. The interrupting event strikes
during spellcasting if it comes between when you start and when you
complete a spell (for a spell with a casting time of 1 full round or
more) or if it comes in response to your casting the spell (such as an
attack of opportunity provoked by the spell or a contingent attack,
such as a readied action).</p>
    <p>If you are taking continuous damage half the damage is
considered to take place while you are casting a spell. You must make a
Concentration check (DC 10 + 1/2 the damage that the continuous source
last dealt + the level of the spell you&#8217;re casting). If the last damage
dealt was the last damage that the effect could deal then the damage is
over, and it does not distract you. Repeated damage does not count as
continuous damage.</p>
    <a id="spell"></a><p><b>Spell</b>: If you are affected by a spell while attempting
to cast a spell of your own, you must make a Concentration check or
lose the spell you are casting. If the spell affecting you deals
damage, the DC is 10 + points of damage + the level of the spell you&#8217;re
casting.</p>
    <p>If the spell interferes with you or distracts you in some
other way, the DC is the spell&#8217;s saving throw DC + the level of the
spell you&#8217;re casting. For a spell with no saving throw, it&#8217;s the DC
that the spell&#8217;s saving throw would have if a save were allowed.</p>
    <a id="grappling-or-pinned"></a><p><b>Grappling or Pinned</b>: The only spells you can cast while
      <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a> or <a href="abilitiesAndConditions.html#pinned" style={{
        "color": "rgb(87, 158, 182)"
      }}>pinned</a> are those without somatic
components and whose
material components (if any) you have in hand. Even so, you must make a
Concentration check (DC 20 + the level of the spell you&#8217;re casting) or
lose the spell.</p>
    <a id="vigorous-motion"></a><p><b>Vigorous Motion</b>: If you are riding on a moving mount,
taking a bouncy ride in a wagon, on a small boat in rough water,
below-decks in a storm-tossed ship, or simply being jostled in a
similar fashion, you must make a Concentration check (DC 10 + the level
of the spell you&#8217;re casting) or lose the spell. </p>
    <a id="violent-motion"></a><p><b>Violent Motion</b>: If you are on a galloping horse, taking
a very rough ride in a wagon, on a small boat in rapids or in a storm,
on deck in a storm-tossed ship, or being tossed roughly about in a
similar fashion, you must make a Concentration check (DC 15 + the level
of the spell you&#8217;re casting) or lose the spell.</p>
    <a id="violent-weather"></a><p><b>Violent Weather</b>: You must make a Concentration check if
you try to cast a spell in violent weather. If you are in a high wind
carrying <a href="abilitiesAndConditions.html#blinded" style={{
        "color": "rgb(87, 158, 182)"
      }}>blinding</a> rain or sleet, the DC
is 5 + the level of the spell
you&#8217;re casting. If you are in wind-driven hail, dust, or debris, the DC
is 10 + the level of the spell you&#8217;re casting. In either case, you lose
the spell if you fail the Concentration check. If the weather is caused
by a spell, use the rules in the Spell subsection above.</p>
    <a id="casting-defensively"></a><p><b>Casting Defensively</b>: If you want to cast a spell
without provoking any attacks of opportunity, you must make a
Concentration check (DC 15 + the level of the spell you&#8217;re casting) to
succeed. You lose the spell if you fail.</p>
    <a id="entangles"></a><p><b>Entangled</b>: If you want to cast a spell while <a href="abilitiesAndConditions.html#entangled" style={{
        "color": "rgb(87, 158, 182)"
      }}>entangled</a>
in a net or by a tanglefoot bag or while you&#8217;re affected by a spell
with similar effects, you must make a DC 15 Concentration check to cast
the spell. You lose the spell if you fail.</p>
    <a id="counterspells"></a><h5>COUNTERSPELLS</h5>
    <p className="initial">It is possible to cast any spell as a
counterspell. By doing so, you are using the spell&#8217;s energy to disrupt
the casting of the same spell by another character. Counterspelling
works even if one spell is divine and the other arcane.</p>
    <a id="how-counterspells-work"></a><p><b>How Counterspells Work</b>: To use a counterspell, you must
select an opponent as the target of the counterspell. You do this by
choosing the ready action. In doing so, you elect to wait to complete
your action until your opponent tries to cast a spell. (You may still
move your speed, since ready is a standard action.)</p>
    <p>If the target of your counterspell tries to cast a spell, make
a <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
check (DC 15 + the spell&#8217;s level). This check is a free
action. If the check succeeds, you correctly identify the opponent&#8217;s
spell and can attempt to counter it. If the check fails, you can&#8217;t do
either of these things.</p>
    <p>To complete the action, you must then cast the correct spell.
As a general rule, a spell can only counter itself. If you are able to
cast the same spell and you have it prepared (if you prepare spells),
you cast it, altering it slightly to create a counterspell effect. If
the target is within range, both spells automatically negate each other
with no other results.</p>
    <a id="counterspelling-metamagic-spells"></a><p><b>Counterspelling Metamagic Spells</b>: Metamagic feats are
not taken into account when determining whether a spell can be countered</p>
    <a id="coiunterspelling-specific-exceptions"></a><p><b>Specific Exceptions</b>: Some spells specifically counter
each other, especially when they have diametrically opposed effects.</p>
    <a id="dispel-as-a-counterspell"></a><p><b>Dispel Magic as a Counterspell</b>: You can use dispel
magic to counterspell another spellcaster, and you don&#8217;t need to
identify the spell he or she is casting. However, dispel magic doesn&#8217;t
always work as a counterspell (see the spell description).</p>
    <a id="caster-level"></a><h5>CASTER LEVEL</h5>
    <p className="initial">A spell&#8217;s power often depends on its caster
level, which for most spellcasting characters is equal to your class
level in the class you&#8217;re using to cast the spell. </p>
    <p>You can cast a spell at a lower caster level than normal, but
the caster level you choose must be high enough for you to cast the
spell in question, and all level-dependent features must be based on
the same caster level. </p>
    <p>In the event that a class feature, domain granted power, or
other special ability provides an adjustment to your caster level, that
adjustment applies not only to effects based on caster level (such as
range, duration, and damage dealt) but also to your caster level check
to overcome your target&#8217;s spell resistance and to the caster level used
in dispel checks (both the dispel check and the DC of the check). </p>
    <a id="spell-failure"></a><h5>SPELL FAILURE</h5>
    <p className="initial">If you ever try to cast a spell in conditions
where the characteristics of the spell cannot be made to conform, the
casting fails and the spell is wasted.</p>
    <p>Spells also fail if your concentration is broken and might
fail if you&#8217;re wearing armor while casting a spell with somatic
components.</p>
    <a id="the-spells-results"></a><h5>THE SPELL&#8217;S RESULT</h5>
    <p className="initial">Once you know which creatures (or objects or
areas) are affected, and whether those creatures have made successful
saving throws (if any were allowed), you can apply whatever results a
spell entails.</p>
    <a id="special-spell-effects"></a><h5>SPECIAL SPELL EFFECTS</h5>
    <p className="initial">Many special spell effects are handled
according to the school of the spells in question Certain other special
spell features are found across spell schools.</p>
    <a id="attacks"></a><p><b>Attacks</b>: Some spell descriptions refer to attacking.
All offensive combat actions, even those that don&#8217;t damage opponents
are considered attacks. Attempts to turn or rebuke undead count as
attacks. All spells that opponents resist with saving throws, that deal
damage, or that otherwise harm or hamper subjects are attacks. Spells
that summon monsters or other allies are not attacks because the spells
themselves don&#8217;t harm anyone.</p>
    <a id="bonus-types"></a><p><b>Bonus Types</b>: Usually, a bonus has a type that indicates
how the spell grants the bonus. The important aspect of bonus types is
that two bonuses of the same type don&#8217;t generally stack. With the
exception of dodge bonuses, most circumstance bonuses, and racial
bonuses, only the better bonus works (see Combining Magical Effects,
below). The same principle applies to penalties&#8212;a character taking two
or more penalties of the same type applies only the worst one.</p>
    <a id="bringing-back-the-dead"></a><p><b>Bringing Back the Dead</b>: Several spells have the power
to restore slain characters to life.</p>
    <p>When a living creature dies, its soul departs its body, leaves
the Material Plane, travels through the Astral Plane, and goes to abide
on the plane where the creature&#8217;s deity resides. If the creature did
not worship a deity, its soul departs to the plane corresponding to its
alignment. Bringing someone back from the dead means retrieving his or
her soul and returning it to his or her body.</p>
    <a id="level-loss"></a><p><i>Level Loss</i>: Any creature brought back to life usually
loses one level of experience. The character&#8217;s new XP total is midway
between the minimum needed for his or her new (reduced) level and the
minimum needed for the next one. If the character was 1st level at the
time of death, he or she loses 2 points of Constitution instead of
losing a level.</p>
    <p>This level loss or Constitution loss cannot be repaired by any
mortal means, even wish or miracle. A revived character can regain a
lost level by earning XP through further adventuring. A revived
character who was 1st level at the time of death can regain lost points
of Constitution by improving his or her Constitution score when he or
she attains a level that allows an ability score increase.</p>
    <a id="preventing-revivification"></a><p><i>Preventing Revivification</i>: Enemies can take steps to
make it more difficult for a character to be returned from the dead.
Keeping the body prevents others from using raise dead or resurrection
to restore the slain character to life. Casting trap the soul prevents
any sort of revivification unless the soul is first released.</p>
    <a id="revivification-against-ones-will"></a><p><i>Revivification against One&#8217;s Will</i>: A soul cannot be
returned to life if it does not wish to be. A soul knows the name,
alignment, and patron deity (if any) of the character attempting to
revive it and may refuse to return on that basis.</p>
    <a id="combining-magical-effects"></a><h5>COMBINING MAGICAL EFFECTS</h5>
    <p className="initial">Spells or magical effects usually work as
described, no matter how many other spells or magical effects happen to
be operating in the same area or on the same recipient. Except in
special cases, a spell does not affect the way another spell operates.
Whenever a spell has a specific effect on other spells, the spell
description explains that effect. Several other general rules apply
when spells or magical effects operate in the same place:</p>
    <a id="stacking-effects"></a><p><b>Stacking Effects</b>: Spells that provide bonuses or
penalties on attack rolls, damage rolls, saving throws, and other
attributes usually do not stack with themselves.</p>
    <p>More generally, two bonuses of the same type don&#8217;t stack even
if they come from different spells (or from effects other than spells;
see Bonus Types, above). </p>
    <a id="different-bonus-names"></a><p><i>Different Bonus Names</i>: The bonuses or penalties from
two different spells stack if the modifiers are of different types.</p>
    <p>A bonus that isn&#8217;t named stacks with any bonus.</p>
    <a id="same-effect-more-than-once-in-different-strengths"></a><p><i>Same Effect More than Once in Different Strengths</i>: In
cases when two or more identical spells are operating in the same area
or on the same target, but at different strengths, only the best one
applies.</p>
    <a id="same-effect-with-differing-results"></a><p><i>Same Effect with Differing Results</i>: The same spell can
sometimes produce varying effects if applied to the same recipient more
than once. Usually the last spell in the series trumps the others. None
of the previous spells are actually removed or dispelled, but their
effects become irrelevant while the final spell in the series lasts.</p>
    <a id="one-effect-makes-another-irrelevant"></a><p><i>One Effect Makes Another Irrelevant</i>: Sometimes, one
spell can render a later spell irrelevant. Both spells are still
active, but one has rendered the other useless in some fashion.</p>
    <a id="multiple-mental-control-effects"></a><p><i>Multiple Mental Control Effects</i>: Sometimes magical
effects that establish mental control render each other irrelevant,
such as a spell that removes the subjects ability to act. Mental
controls that don&#8217;t remove the recipient&#8217;s ability to act usually do
not interfere with each other. If a creature is under the mental
control of two or more creatures, it tends to obey each to the best of
its ability, and to the extent of the control each effect allows. If
the controlled creature receives conflicting orders simultaneously, the
competing controllers must make opposed Charisma checks to determine
which one the creature obeys.</p>
    <a id="spells-with-opposite-effects"></a><p><b>Spells with Opposite Effects</b>: Spells with opposite
effects apply normally, with all bonuses, penalties, or changes
accruing in the order that they apply. Some spells negate or counter
each other. This is a special effect that is noted in a spell&#8217;s
description. </p>
    <a id="instantaneous-effects"></a><p><b>Instantaneous Effects</b>: Two or more spells with
instantaneous durations work cumulatively when they affect the same
target.</p>
    <a id="spell-descriptions"></a><h3>SPELL DESCRIPTIONS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">The description of each spell is presented in
a standard format. Each category of information is explained and
defined below.</p>
    <a id="spell-name"></a><h5>NAME</h5>
    <p className="initial">The first line of every spell description
gives the name by which the spell is generally known.</p>
    <a id="school-subschool"></a><h5>SCHOOL (SUBSCHOOL)</h5>
    <p className="initial">Beneath the spell name is a line giving the <a href="magicSchools.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>school of
magic</a> (and the subschool, if appropriate) that the spell belongs to.</p>
    <a id="descriptor"></a><h5>[DESCRIPTOR]</h5>
    <p className="initial">Appearing on the same line as the school and
subschool, when applicable, is a descriptor that further categorizes
the spell in some way. Some spells have more than one descriptor.</p>
    <p>The descriptors are acid, air, chaotic, cold, darkness, death,
earth, electricity, evil, fear, fire, force, good, language-dependent,
lawful, light, mind-affecting, sonic, and water.</p>
    <p>Most of these descriptors have no game effect by themselves,
but they govern how the spell interacts with other spells, with special
abilities, with unusual creatures, with alignment, and so on.</p>
    <p>A language-dependent spell uses intelligible language as a
medium for communication. If the target cannot understand or cannot
hear what the caster of a language-dependant spell says the spell fails.</p>
    <p>A mind-affecting spell works only against creatures with an
Intelligence score of 1 or higher.</p>
    <a id="level"></a><h5>LEVEL</h5>
    <p className="initial">The next line of a spell description gives the
spell&#8217;s level, a number between 0 and 9 that defines the spell&#8217;s
relative power. This number is preceded by an abbreviation for the
class whose members can cast the spell. The Level entry also indicates
whether a spell is a domain spell and, if so, what its domain and its
level as a domain spell are. A spell&#8217;s level affects the DC for any
save allowed against the effect.</p>
    <p>Names of spellcasting classes are abbreviated as follows: bard
Brd; cleric Clr; druid Drd; paladin Pal; ranger Rgr; sorcerer Sor;
wizard Wiz.</p>
    <p>The domains a spell can be associated with include Air,
Animal, Chaos, Death, Destruction, Earth, Evil, Fire, Good, Healing,
Knowledge, Law, Luck, Magic, Plant, Protection, Strength, Sun, Travel,
Trickery, War, and Water.</p>
    <a id="components"></a><h5>COMPONENTS</h5>
    <p className="initial">A spell&#8217;s components are what you must do or
possess to cast it. The Components entry in a spell description
includes abbreviations that tell you what type of components it has.
Specifics for material, focus, and XP components are given at the end
of the descriptive text. Usually you don&#8217;t worry about components, but
when you can&#8217;t use a component for some reason or when a material or
focus component is expensive, then the components are important.</p>
    <a id="verbal"></a><p><b>Verbal (V)</b>: A verbal component is a spoken incantation.
To provide a verbal component, you must be able to speak in a strong
voice. A silence spell or a gag spoils the incantation (and thus the
spell). A spellcaster who has been <a href="abilitiesAndConditions.html#deafened" style={{
        "color": "rgb(87, 158, 182)"
      }}>deafened</a> has a 20% chance to
spoil
any spell with a verbal component that he or she tries to cast.</p>
    <a id="somatic"></a><p><b>Somatic (S)</b>: A somatic component is a measured and
precise movement of the hand. You must have at least one hand free to
provide a somatic component.</p>
    <a id="material"></a><p><b>Material (M)</b>: A material component is one or more
physical substances or objects that are annihilated by the spell
energies in the casting process. Unless a cost is given for a material
component, the cost is negligible. Don&#8217;t bother to keep track of
material components with negligible cost. Assume you have all you need
as long as you have your spell component pouch.</p>
    <a id="focus"></a><p><b>Focus (F)</b>: A focus component is a prop of some sort.
Unlike a material component, a focus is not consumed when the spell is
cast and can be reused. As with material components, the cost for a
focus is negligible unless a price is given. Assume that focus
components of negligible cost are in your spell component pouch.</p>
    <a id="divine-focus"></a><p><b>Divine Focus (DF)</b>: A divine focus component is an item
of spiritual significance. The divine focus for a cleric or a paladin
is a holy symbol appropriate to the character&#8217;s faith. </p>
    <p>If the Components line includes F/DF or M/DF, the arcane
version of the spell has a focus component or a material component (the
abbreviation before the slash) and the divine version has a divine
focus component (the abbreviation after the slash).</p>
    <a id="xp-cost"></a><p><b>XP Cost (XP)</b>: Some powerful spells entail an experience
point cost to you. No spell can restore the XP lost in this manner. You
cannot spend so much XP that you lose a level, so you cannot cast the
spell unless you have enough XP to spare. However, you may, on gaining
enough XP to attain a new level, use those XP for casting a spell
rather than keeping them and advancing a level. The XP are treated just
like a material component&#8212;expended when you cast the spell, whether or
not the casting succeeds.</p>
    <a id="casting-time"></a><h5>CASTING TIME</h5>
    <p className="initial">Most spells have a casting time of 1 standard
action. Others take 1 round or more, while a few require only a free
action.</p>
    <p>A spell that takes 1 round to cast is a full-round action. It
comes into effect just before the beginning of your turn in the round
after you began casting the spell. You then act normally after the
spell is completed.</p>
    <p>A spell that takes 1 minute to cast comes into effect just
before your turn 1 minute later (and for each of those 10 rounds, you
are casting a spell as a full-round action, just as noted above for 1-
round casting times). These actions must be consecutive and
uninterrupted, or the spell automatically fails.</p>
    <p>When you begin a spell that takes 1 round or longer to cast,
you must continue the concentration from the current round to just
before your turn in the next round (at least). If you lose
concentration before the casting is complete, you lose the spell.</p>
    <p>A spell with a casting time of 1 free action doesn&#8217;t count
against your normal limit of one spell per round. However, you may cast
such a spell only once per round. Casting a spell with a casting time
of 1 free action doesn&#8217;t provoke attacks of opportunity.</p>
    <p>You make all pertinent decisions about a spell (range, target,
area, effect, version, and so forth) when the spell comes into effect.</p>
    <a id="range"></a><h5>RANGE</h5>
    <p className="initial">A spell&#8217;s range indicates how far from you it
can reach, as defined in the Range entry of the spell description. A
spell&#8217;s range is the maximum distance from you that the spell&#8217;s effect
can occur, as well as the maximum distance at which you can designate
the spell&#8217;s point of origin. If any portion of the spell&#8217;s area would
extend beyond this range, that area is wasted. Standard ranges include
the following.</p>
    <a id="range-personal"></a><p><b>Personal</b>: The spell affects only you.</p>
    <a id="range-touch"></a><p><b>Touch</b>: You must touch a creature or object to affect
it. A touch spell that deals damage can score a critical hit just as a
weapon can. A touch spell threatens a critical hit on a natural roll of
20 and deals double damage on a successful critical hit. Some touch
spells allow you to touch multiple targets. You can touch as many
willing targets as you can reach as part of the casting, but all
targets of the spell must be touched in the same round that you finish
casting the spell.</p>
    <a id="range-close"></a><p><b>Close</b>: The spell reaches as far as 25 feet away from
you. The maximum range increases by 5 feet for every two full caster
levels.</p>
    <a id="range-medium"></a><p><b>Medium</b>: The spell reaches as far as 100 feet + 10 feet
per caster level.</p>
    <a id="range-long"></a><p><b>Long</b>: The spell reaches as far as 400 feet + 40 feet
per caster level.</p>
    <a id="range-unlimited"></a><p><b>Unlimited</b>: The spell reaches anywhere on the same plane
of existence.</p>
    <a id="range-expressed-in-feet"></a><p><b>Range Expressed in Feet</b>: Some spells have no standard
range category, just a range expressed in feet.</p>
    <a id="aiming-a-spell"></a><h5>AIMING A SPELL</h5>
    <p className="initial">You must make some choice about whom the spell
is to affect or where the effect is to originate, depending on the type
of spell. The next entry in a spell description defines the spell&#8217;s
target (or targets), its effect, or its area, as appropriate.</p>
    <a id="target-or-targets"></a><p><b>Target or Targets</b>: Some spells have a target or
targets. You cast these spells on creatures or objects, as defined by
the spell itself. You must be able to see or touch the target, and you
must specifically choose that target. You do not have to select your
target until you finish casting the spell.</p>
    <p>If the target of a spell is yourself (the spell description
has a line that reads Target: You), you do not receive a saving throw,
and spell resistance does not apply. The Saving Throw and Spell
Resistance lines are omitted from such spells.</p>
    <p>Some spells restrict you to willing targets only. Declaring
yourself as a willing target is something that can be done at any time
(even if you&#8217;re <a href="abilitiesAndConditions.html#flat-footed" style={{
        "color": "rgb(87, 158, 182)"
      }}>flat-footed</a> or it isn&#8217;t your
turn). <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>Unconscious</a>
creatures are automatically considered willing, but a character who is
conscious but immobile or <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> (such as one who is
bound, <a href="abilitiesAndConditions.html#cowering" style={{
        "color": "rgb(87, 158, 182)"
      }}>cowering</a>, <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a>, <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a>, <a href="abilitiesAndConditions.html#pinned" style={{
        "color": "rgb(87, 158, 182)"
      }}>pinned</a>, or <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stunned</a>) is not automatically
willing.</p>
    <p>Some spells allow you to redirect the effect to new targets or
areas after you cast the spell. Redirecting a spell is a move action
that does not provoke attacks of opportunity.</p>
    <a id="effect"></a><p><b>Effect</b>: Some spells create or summon things rather than
affecting things that are already present. You must designate the
location where these things are to appear, either by seeing it or
defining it. Range determines how far away an effect can appear, but if
the effect is mobile it can move regardless of the spell&#8217;s range.</p>
    <a id="ray"></a><p><i>Ray</i>: Some effects are rays. You aim a ray as if using a
ranged weapon, though typically you make a ranged touch attack rather
than a normal ranged attack. As with a ranged weapon, you can fire into
the dark or at an <a href="abilitiesAndConditions.html#invisible" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisible</a> creature and hope you
hit something. You
don&#8217;t have to see the creature you&#8217;re trying to hit, as you do with a
targeted spell. Intervening creatures and obstacles, however, can block
your line of sight or provide cover for the creature you&#8217;re aiming at.</p>
    <p>If a ray spell has a duration, it&#8217;s the duration of the effect
that the ray causes, not the length of time the ray itself persists.</p>
    <p>If a ray spell deals damage, you can score a critical hit just
as if it were a weapon. A ray spell threatens a critical hit on a
natural roll of 20 and deals double damage on a successful critical hit.</p>
    <a id="spread"></a><p><i>Spread</i>: Some effects, notably clouds and fogs, spread
out from a point of origin, which must be a grid intersection. The
effect can extend around corners and into areas that you can&#8217;t see.
Figure distance by actual distance traveled, taking into account turns
the spell effect takes. When determining distance for spread effects,
count around walls, not through them. As with movement, do not trace
diagonals across corners. You must designate the point of origin for
such an effect, but you need not have line of effect (see below) to all
portions of the effect.</p>
    <a id="area"></a><p><b>Area</b>: Some spells affect an area. Sometimes a spell
description specifies a specially defined area, but usually an area
falls into one of the categories defined below.</p>
    <p>Regardless of the shape of the area, you select the point
where the spell originates, but otherwise you don&#8217;t control which
creatures or objects the spell affects. The point of origin of a spell
is always a grid intersection. When determining whether a given
creature is within the area of a spell, count out the distance from the
point of origin in squares just as you do when moving a character or
when determining the range for a ranged attack. The only difference is
that instead of counting from the center of one square to the center of
the next, you count from intersection to intersection. You can count
diagonally across a square, but remember that every second diagonal
counts as 2 squares of distance. If the far edge of a square is within
the spell&#8217;s area, anything within that square is within the spell&#8217;s
area. If the spell&#8217;s area only touches the near edge of a square,
however, anything within that square is unaffected by the spell.</p>
    <a id="burst-emanation-or-spread"></a><p><i>Burst, Emanation, or Spread</i>: Most spells that affect an
area function as a burst, an emanation, or a spread. In each case, you
select the spell&#8217;s point of origin and measure its effect from that
point.</p>
    <p>A burst spell affects whatever it catches in its area, even
including creatures that you can&#8217;t see. It can&#8217;t affect creatures with
total cover from its point of origin (in other words, its effects don&#8217;t
extend around corners). The default shape for a burst effect is a
sphere, but some burst spells are specifically described as
cone-shaped. A burst&#8217;s area defines how far from the point of origin
the spell&#8217;s effect extends.</p>
    <p>An emanation spell functions like a burst spell, except that
the effect continues to radiate from the point of origin for the
duration of the spell. Most emanations are cones or spheres.</p>
    <p>A spread spell spreads out like a burst but can turn corners.
You select the point of origin, and the spell spreads out a given
distance in all directions. Figure the area the spell effect fills by
taking into account any turns the spell effect takes.</p>
    <a id="cone-cylinder-line-or-sphere"></a><p><i>Cone, Cylinder, Line, or Sphere</i>: Most spells that
affect an area have a particular shape, such as a cone, cylinder, line,
or sphere.</p>
    <p>A cone-shaped spell shoots away from you in a quarter-circle
in the direction you designate. It starts from any corner of your
square and widens out as it goes. Most cones are either bursts or
emanations (see above), and thus won&#8217;t go around corners.</p>
    <p>When casting a cylinder-shaped spell, you select the spell&#8217;s
point of origin. This point is the center of a horizontal circle, and
the spell shoots down from the circle, filling a cylinder. A
cylinder-shaped spell ignores any obstructions within its area.</p>
    <p>A line-shaped spell shoots away from you in a line in the
direction you designate. It starts from any corner of your square and
extends to the limit of its range or until it strikes a barrier that
blocks line of effect. A line-shaped spell affects all creatures in
squares that the line passes through.</p>
    <p>A sphere-shaped spell expands from its point of origin to fill
a spherical area. Spheres may be bursts, emanations, or spreads.</p>
    <a id="creatures"></a><p><i>Creatures</i>: A spell with this kind of area affects
creatures directly (like a targeted spell), but it affects all
creatures in an area of some kind rather than individual creatures you
select. The area might be a spherical burst , a cone-shaped burst, or
some other shape.</p>
    <p>Many spells affect &#8220;living creatures,&#8221; which means all
creatures other than constructs and undead. Creatures in the spell&#8217;s
area that are not of the appropriate type do not count against the
creatures affected.</p>
    <a id="objects"></a><p><i>Objects</i>: A spell with this kind of area affects objects
within an area you select (as Creatures, but affecting objects instead).</p>
    <a id="other"></a><p><i>Other</i>: A spell can have a unique area, as defined in
its description.</p>
    <a id="shapeable"></a><p><i>(S) Shapeable</i>: If an Area or Effect entry ends with
&#8220;(S),&#8221; you can shape the spell. A shaped effect or area can have no
dimension smaller than 10 feet. Many effects or areas are given as
cubes to make it easy to model irregular shapes. Three-dimensional
volumes are most often needed to define aerial or underwater effects
and areas.</p>
    <a id="line-of-effect"></a><p><b>Line of Effect</b>: A line of effect is a straight,
unblocked path that indicates what a spell can affect. A line of effect
is canceled by a solid barrier. It&#8217;s like line of sight for ranged
weapons, except that it&#8217;s not blocked by fog, darkness, and other
factors that limit normal sight.</p>
    <p>You must have a clear line of effect to any target that you
cast a spell on or to any space in which you wish to create an effect.
You must have a clear line of effect to the point of origin of any
spell you cast. A burst, cone, cylinder, or emanation spell affects
only an area, creatures, or objects to which it has line of effect from
its origin (a spherical burst&#8217;s center point, a cone-shaped burst&#8217;s
starting point, a cylinder&#8217;s circle, or an emanation&#8217;s point of origin).</p>
    <p>An otherwise solid barrier with a hole of at least 1 square
foot through it does not block a spell&#8217;s line of effect. Such an
opening means that the 5-foot length of wall containing the hole is no
longer considered a barrier for purposes of a spell&#8217;s line of effect.</p>
    <a id="duration"></a><h5>DURATION</h5>
    <p className="initial">A spell&#8217;s Duration entry tells you how long
the magical energy of the spell lasts.</p>
    <a id="timed-durations"></a><p><b>Timed Durations</b>: Many durations are measured in rounds,
minutes, hours, or some other increment. When the time is up, the magic
goes away and the spell ends. If a spell&#8217;s duration is variable the
duration is rolled secretly (the caster doesn&#8217;t know how long the spell
will last). </p>
    <a id="instantaneous"></a><p><b>Instantaneous</b>: The spell energy comes and goes the
instant the spell is cast, though the consequences might be
long-lasting.</p>
    <a id="permanent"></a><p><b>Permanent</b>: The energy remains as long as the effect
does. This means the spell is vulnerable to dispel magic. </p>
    <a id="duration-concentration"></a><p><b>Concentration</b>: The spell lasts as long as you
concentrate on it. Concentrating to maintain a spell is a standard
action that does not provoke attacks of opportunity. Anything that
could break your concentration when casting a spell can also break your
concentration while you&#8217;re maintaining one, causing the spell to end.
You can&#8217;t cast a spell while concentrating on another one. Sometimes a
spell lasts for a short time after you cease concentrating.</p>
    <a id="subjects-effects-and-areas"></a><p><b>Subjects, Effects, and Areas</b>: If the spell affects
creatures directly the result travels with the subjects for the spell&#8217;s
duration. If the spell creates an effect, the effect lasts for the
duration. The effect might move or remain still. Such an effect can be
destroyed prior to when its duration ends. If the spell affects an area
then the spell stays with that area for its duration. Creatures become
subject to the spell when they enter the area and are no longer subject
to it when they leave.</p>
    <a id="touch-spells-and-holding-the-charge"></a><p><b>Touch Spells and Holding the Charge</b>: In most cases, if
you don&#8217;t discharge a touch spell on the round you cast it, you can
hold the charge (postpone the discharge of the spell) indefinitely. You
can make touch attacks round after round. If you cast another spell,
the touch spell dissipates.</p>
    <p>Some touch spells allow you to touch multiple targets as part
of the spell. You can&#8217;t hold the charge of such a spell; you must touch
all targets of the spell in the same round that you finish casting the
spell.</p>
    <a id="discharge"></a><p><b>Discharge</b>: Occasionally a spells lasts for a set
duration or until triggered or discharged.</p>
    <a id="dismissible"></a><p><b>(D) Dismissible</b>: If the Duration line ends with &#8220;(D),&#8221;
you can dismiss the spell at will. You must be within range of the
spell&#8217;s effect and must speak words of dismissal, which are usually a
modified form of the spell&#8217;s verbal component. If the spell has no
verbal component, you can dismiss the effect with a gesture. Dismissing
a spell is a standard action that does not provoke attacks of
opportunity. A spell that depends on concentration is dismissible by
its very nature, and dismissing it does not take an action, since all
you have to do to end the spell is to stop concentrating on your turn.</p>
    <a id="saving-throw"></a><h5>SAVING THROW</h5>
    <p className="initial">Usually a harmful spell allows a target to
make a saving throw to avoid some or all of the effect. The Saving
Throw entry in a spell description defines which type of saving throw
the spell allows and describes how saving throws against the spell work.</p>
    <a id="negates"></a><p><b>Negates</b>: The spell has no effect on a subject that
makes a successful saving throw.</p>
    <a id="partial"></a><p><b>Partial</b>: The spell causes an effect on its subject. A
successful saving throw means that some lesser effect occurs.</p>
    <a id="half"></a><p><b>Half</b>: The spell deals damage, and a successful saving
throw halves the damage taken (round down).</p>
    <a id="none"></a><p><b>None</b>: No saving throw is allowed.</p>
    <a id="disbelief"></a><p><b>Disbelief</b>: A successful save lets the subject ignore
the effect. </p>
    <a id="object"></a><p><b>(object)</b>: The spell can be cast on objects, which
receive saving throws only if they are magical or if they are attended
(held, worn, grasped, or the like) by a creature resisting the spell,
in which case the object uses the creature&#8217;s saving throw bonus unless
its own bonus is greater. (This notation does not mean that a spell can
be cast only on objects. Some spells of this sort can be cast on
creatures or objects.) A magic item&#8217;s saving throw bonuses are each
equal to 2 + one-half the item&#8217;s caster level. </p>
    <a id="harmless"></a><p><b>(harmless)</b>: The spell is usually beneficial, not
harmful, but a targeted creature can attempt a saving throw if it
desires.</p>
    <a id="saving-throw-difficulty-class"></a><p><b>Saving Throw Difficulty Class</b>: A saving throw against
your spell has a DC of 10 + the level of the spell + your bonus for the
relevant ability (Intelligence for a wizard, Charisma for a sorcerer or
bard, or Wisdom for a cleric, druid, paladin, or ranger). A spell&#8217;s
level can vary depending on your class. Always use the spell level
applicable to your class.</p>
    <a id="succeeding-on-a-saving-throw"></a><p><b>Succeeding on a Saving Throw</b>: A creature that
successfully saves against a spell that has no obvious physical effects
feels a hostile force or a tingle, but cannot deduce the exact nature
of the attack. Likewise, if a creature&#8217;s saving throw succeeds against
a targeted spell you sense that the spell has failed. You do not sense
when creatures succeed on saves against effect and area spells.</p>
    <a id="automatic-failures-and-successes"></a><p><b>Automatic Failures and Successes</b>: A natural 1 (the d20
comes up 1) on a saving throw is always a failure, and the spell may
cause damage to exposed items (see Items Surviving after a Saving
Throw, below). A natural 20 (the d20 comes up 20) is always a success.</p>
    <a id="voluntarily-giving-up-a-saving-throw"></a><p><b>Voluntarily Giving up a Saving Throw</b>: A creature can
voluntarily forego a saving throw and willingly accept a spell&#8217;s
result. Even a character with a special resistance to magic can
suppress this quality.</p>
    <a id="items-surviving-after-a-saving-throw"></a><p><b>Items Surviving after a Saving Throw</b>: Unless the
descriptive text for the spell specifies otherwise, all items carried
or worn by a creature are assumed to survive a magical attack. If a
creature rolls a natural 1 on its saving throw against the effect,
however, an exposed item is harmed (if the attack can harm objects).
Refer to Table: Items Affected by Magical Attacks. Determine which four
objects carried or worn by the creature are most likely to be affected
and roll randomly among them. The randomly determined item must make a
saving throw against the attack form and take whatever damage the
attack deal.</p>
    <p>If an item is not carried or worn and is not magical, it does
not get a saving throw. It simply is dealt the appropriate damage.</p>
    <a id="table-items-affected-by-magical-attacks"></a><p><b>Table: Items Affected by Magical Attacks</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "75px"
          }}>Order<sup>1</sup></th>
          <th>Item</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>Shield</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>Armor</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>Magic helmet, hat, or headband</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>Item in hand (including weapon, wand, or the like)</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>Magic cloak</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>Stowed or sheathed weapon</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>Magic bracers</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>Magic clothing</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>Magic jewelry (including rings)</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">Anything else</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>In order of most likely to least
likely to be affected.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="spell-resistance"></a><h5>SPELL RESISTANCE</h5>
    <p className="initial">Spell resistance is a special defensive
ability. If your spell is being resisted by a creature with spell
resistance, you must make a caster level check (1d20 + caster level) at
least equal to the creature&#8217;s spell resistance for the spell to affect
that creature. The defender&#8217;s spell resistance is like an Armor Class
against magical attacks. Include any adjustments to your caster level
to this caster level check.</p>
    <p>The Spell Resistance entry and the descriptive text of a spell
description tell you whether spell resistance protects creatures from
the spell. In many cases, spell resistance applies only when a
resistant creature is targeted by the spell, not when a resistant
creature encounters a spell that is already in place.</p>
    <p>The terms &#8220;object&#8221; and &#8220;harmless&#8221; mean the same thing for
spell resistance as they do for saving throws. A creature with spell
resistance must voluntarily lower the resistance (a standard action) in
order to be affected by a spell noted as harmless. In such a case, you
do not need to make the caster level check described above.</p>
    <a id="decriptive"></a><h5>DESCRIPTIVE TEXT</h5>
    <p className="initial">This portion of a spell description details
what the spell does and how it works. If one of the previous entries in
the description included &#8220;see text,&#8221; this is where the explanation is
found. </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      